import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "wat"
};
const _hoisted_2 = {
  class: "tables"
};
const _hoisted_3 = {
  class: "acea-row row-center-wrapper nots"
};
const _hoisted_4 = {
  key: 0,
  class: "constTables"
};
const _hoisted_5 = {
  class: "th acea-row row-between-wrapper"
};
const _hoisted_6 = {
  class: "text_center"
};
const _hoisted_7 = {
  class: "text_rit"
};
const _hoisted_8 = {
  class: "text_center"
};
const _hoisted_9 = {
  class: "text_rit"
};
const _hoisted_10 = {
  class: "pagination"
};
const _hoisted_11 = {
  key: 1,
  class: "no_more"
};
import { router } from '@/router';
import { team } from '@/http';
import { ref } from 'vue';
export default {
  __name: 'team',
  setup(__props) {
    const total = ref(0);
    const pageSize = ref(10);
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const page = ref(1);
    const _team = async () => {
      const {
        data
      } = await team({
        page: page.value,
        perPage: pageSize.value
      });
      list.value = data.data;
      total.value = data.total;
    };
    _team();
    const changeList = net => {
      page.value = net;
      _team();
    };
    const back = () => {
      router.go(-1);
    };
    return (_ctx, _cache) => {
      const _component_el_pagination = _resolveComponent("el-pagination");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('my_team')), 1)]), list.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", null, _toDisplayString(_ctx.$t('account')), 1), _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('invitation_time')), 1), _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('level')), 1)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "tds acea-row row-between-wrapper",
          key: index
        }, [_createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString(item.email ? item.email : item.phone), 1)]), _createElementVNode("div", _hoisted_8, [_createElementVNode("span", null, _toDisplayString(item.created_at), 1)]), _createElementVNode("div", _hoisted_9, [_createElementVNode("span", null, _toDisplayString(item.layers), 1)])]);
      }), 128)), _createElementVNode("div", _hoisted_10, [total.value > pageSize.value ? (_openBlock(), _createBlock(_component_el_pagination, {
        key: 0,
        size: "small",
        background: "",
        layout: "prev, pager, next",
        total: total.value,
        class: "mt-4",
        "page-size": pageSize.value,
        "current-page": page.value[0],
        "onUpdate:currentPage": _cache[0] || (_cache[0] = $event => page.value[0] = $event),
        onChange: changeList
      }, null, 8, ["total", "page-size", "current-page"])) : _createCommentVNode("", true)])])) : (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t('no_more')), 1))])]);
    };
  }
};